import React from "react";

const SWCross = ({ fill, width, height }) => {
  return (
    <svg
      width={ width || "12"}
      height={ height || "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.29382 0.221985C0.997843 -0.0739953 0.517965 -0.0739954 0.221985 0.221984C-0.073995 0.517964 -0.073995 0.997842 0.221985 1.29382L4.92817 6L0.221985 10.7062C-0.0739951 11.0022 -0.0739953 11.482 0.221985 11.778C0.517965 12.074 0.997843 12.074 1.29382 11.778L6.00001 7.07184L10.7062 11.778C11.0022 12.074 11.482 12.074 11.778 11.778C12.074 11.482 12.074 11.0022 11.778 10.7062L7.07184 6L11.778 1.29383C12.074 0.997851 12.074 0.517973 11.778 0.221993C11.482 -0.0739869 11.0022 -0.0739869 10.7062 0.221993L6 4.92816L1.29382 0.221985Z"
        fill={fill || "#54546A"}
      />
    </svg>
  );
};

export default SWCross;
