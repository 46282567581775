import axios from "axios";
import config from './config/app.json';
import { payloadHash, getutctimestamp, payloadHashKafka } from './config/utils';
import { format } from "date-fns";

const KAFKAAPIURIS = config['KAFKAAPIURIS'][config['KAFKAAPIURIS']['current']]
const API_HOST = config['APIURIS'][config['APIURIS']['current']]
const META_INFO = config['METAINFO'][config['METAINFO']['current']]

export function storeCustomEvent(event, visitor_id, session_id, meta_info, instance_time, token) {
  let payload = {
    event_name: event.event_name,
    event_description: event.description,
    visitor_id,
    session_id,
    meta_info,
    instance_time,
    event_text: event.event_text
  }
  if (event.email) {
    payload = { ...payload, email: event.email }
  }

  return axios.post(`${KAFKAAPIURIS}/partner/custom_event_track/`,
    payload, {
    headers: {
      "x-show-key": token
    }
  });
}

  export function storeCustomAttributes(account_id, attributeData, reservedAttribute, visitor_id, session_id, meta_info, instance_time) {
    var raw = JSON.stringify({
      account_id: account_id,
      user_data: attributeData,
      reserved_attributes: reservedAttribute,
      visitor_id: visitor_id,
      session_id: session_id,
      meta_info: meta_info,
      instance_time: instance_time
    });
    var getutctimestampVal = getutctimestamp()
    var hash = payloadHashKafka(raw, getutctimestampVal);
  
    var requestOptions = {
      method: "POST",
      headers: {
        "Authorization": `Token ${hash}`,
        "Content-Type": "application/json",
        "x-show-player-instance": getutctimestampVal
      },
      body: raw,
      redirect: "follow",
    };
  
    fetch(`${KAFKAAPIURIS}/partner/custom_attribute_track/`, requestOptions)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log('got an error', error);
      })
  }
// export function storeCustomAttributes(account_id, attributeData, reservedAttribute, visitor_id, session_id, meta_info, instance_time) {
//   var payload = {
//     account_id: account_id,
//     user_data: attributeData,
//     reserved_attributes: reservedAttribute,
//     visitor_id: visitor_id,
//     session_id: session_id,
//     meta_info: meta_info,
//     instance_time: instance_time
//   };

//   var getutctimestampVal = getutctimestamp()

//   var hash = payloadHash(payload, getutctimestampVal);

//   var data = axios
//     .post(`${API_HOST}/api/v1/partner/attribute/signal`, payload, {
//       headers: {
//         Authorization: `Token ${hash}`,
//         "x-show-player-instance": getutctimestampVal
//       },
//     })
//     .then((response) => {
//       return response;
//     })
//     .catch(function (error) {
//       console.log(error);
//     });
//   return data;
// }


// get all notificaitons
export const getNotificationPaths = async (type, _payload) => {

    let payload;
    let endpoint;

    // get all notification payload
    if(type === 'all'){
      endpoint = 'get_notification_path'
      payload = {
        owner_account_id: _payload.owner_account_id,
        domain : _payload.domain,
        size: 100,
        page: 1
      };
  
    }

    // get trigger notification
    if(type === 'trigger'){
      endpoint = 'get_trigger_notification'
      payload = {
        owner_account_id: _payload.owner_account_id, 
        email: _payload.email, 
        es_data: true
      } 
    }

    if(!payload){
      console.log('missing payload');
      return
    }

    let getutctimestampVal = getutctimestamp()
    let hash = payloadHash(payload, getutctimestampVal);
  
    let requestOptions = {
      method: "POST",
      headers: {
        "Authorization": `Token ${hash}`,
        "Content-Type": "application/json",
        "x-show-player-instance": getutctimestampVal
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    return fetch(`${API_HOST}/api/v1/notifications/${endpoint}`, requestOptions)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.log('got an error', error);
    })

};

// validate notification
export const validateUserNotification = async (payload) => {

  let body = {
    owner_account_id: payload.owner_account_id,
    rule_queryset: payload.rule_queryset,
    email: payload.email,
    id: payload.id,
  }

  let getutctimestampVal = getutctimestamp()
  let hash = payloadHash(body, getutctimestampVal);

  let requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Token ${hash}`,
      "Content-Type": "application/json",
      "x-show-player-instance": getutctimestampVal
    },
    body: JSON.stringify(body),
    redirect: "follow",
  };

  return fetch(`${API_HOST}/api/v1/notifications/validate_user`, requestOptions)
};


// notification user event tracking
export function notificationUserEvents(payload) {

  let _payload = {
    visitor_id: payload.visitor_id,
    session_id: payload.session_id,
    owner_account_id: payload.owner_account_id,
    meta_info: payload.meta_info,
    signal_name: payload.signal_name,
    notification_data: payload.notification_data,
    instance_time: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx")
  }

  if(payload.email){
    _payload.email = payload.email
  }

  let getutctimestampVal = getutctimestamp()
  let hash = payloadHashKafka(JSON.stringify(_payload), getutctimestampVal);

  let requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Token ${hash}`,
      "Content-Type": "application/json",
      "x-show-player-instance": getutctimestampVal
    },
    body: JSON.stringify(_payload),
    redirect: "follow",
  };

  fetch(`${KAFKAAPIURIS}/notification_tracking/`, requestOptions)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log('got an error', error);
    })
}

// update contact info
export function updateContactInfo({owner_account_id, email, attributes, meta_info}) {
  var raw = JSON.stringify({
    owner_account_id : owner_account_id,
    email : email,
    attributes : attributes,
    meta_info: meta_info
  });

  var getutctimestampVal = getutctimestamp()
  var hash = payloadHashKafka(raw, getutctimestampVal);

  var requestOptions = {
    method: "POST",
    headers: {
      "Authorization": `Token ${hash}`,
      "Content-Type": "application/json",
      "x-show-player-instance": getutctimestampVal
    },
    body: raw,
    redirect: "follow",
  };

  return fetch(`${KAFKAAPIURIS}/update_contact_info/`, requestOptions)
}

// get meta info
export const getMetaInfo = ()=>{
  return fetch(META_INFO).then(response => response.json())
}