import React from "react";
import styled, { keyframes } from "styled-components";
import SWCrossIcon from "../../icon/SWCrossIcon";
import CTAButton from "../Buttons/CTAButton";

import "./Banner.css"

const Banner = ({ notification, closeNotificationBar, isNotificationBarOpened, trackNotificationUserEvent }) => {
  const { description, cta, category: type } = notification.notification

  if (!isNotificationBarOpened) {
    return null
  }

  return (
    <Wrapper $type={type} >
      <section className="description-wrapper">
        <p className="description" dangerouslySetInnerHTML={{__html: description.text}}></p>
        <div className="cta-wrapper">
          {type === 2 && (
            <CTAButton
              label={cta.label}
              href={cta.href}
              className={"banner-ctaBtn"}
              notification={notification}
              trackNotificationUserEvent={trackNotificationUserEvent}
              track
            />
          )}
        </div>
      </section>
      <section className="close-btn-wrapper">
        <span className="close-btn" onClick={closeNotificationBar}>
          <SWCrossIcon />
        </span>
      </section>
    </Wrapper>
  )
}

const slidingFromTop = keyframes`
  from{
    transform: translateY(-100%);
  }
  to{
    transform: translateY(0);
  }
`

const Wrapper = styled.section`
  min-height: 64px;
  background: transparent linear-gradient(93deg, #4E41F1 0%, #9558F1 100%) 0% 0% ;
  /* border-bottom: 1px solid #E8E8E8; */
  & *{
   font-family : Inter !important;
  }

  display: grid;
  grid-template-columns: minmax(0, 1fr) 40px;
  column-gap: 24px;
  align-items: center;

  .description-wrapper{
    padding: 16px 24px;

    display: grid;
    grid-template-columns: ${({ $type }) => {
    return $type === 2 ? "minmax(0,max-content) auto" : "minmax(0, 1fr)"
  }};
    column-gap: 16px;
    align-items: center;
    justify-content: center;
  }

  .description{
    margin: 0 !important;
    color: #fff !important;
    line-height: 18px !important;
    font-size: 15px !important;
    text-align: center;
    font-weight: 500 !important;
  }

  .cta-wrapper a{
    letter-spacing: 0.1px;
  }

  @media screen and (max-width: 830px) {
    grid-template-columns: minmax(0, 1fr) 30px;
    column-gap: 16px;
    
    .description-wrapper{
      padding: 12px 16px;

      display: grid;
      grid-template-columns: minmax(0, 1fr);
      column-gap: 16px;
      row-gap: 16px;
      align-items: center;
      justify-content: center;
    }
    .cta-wrapper a{
      font-size: 14px;
      line-height: inherit;
    }
    
    .cta-wrapper{
      display: flex;
      justify-content: center;
      font-size: 14px;
    }
  }

  animation-name: ${slidingFromTop};
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
`


export default Banner;