import React from "react";
import "./ImageWithButton.css";
import { useInAppNotificationContext } from "../../context/InAppNotificationContext";
import CTAButton from "../Buttons/CTAButton";

const ImageWithButton = (props) => {
  const { title, description, image, cta } = props.notification.notification
  const { trackNotificationUserEvent } = useInAppNotificationContext()

  return (
    <section className="showIAN-popupContent-container">
      {title.visibility && (
        <div className="showIAN-popupContent-headerWrapper" >
          <h1 className="showIAN-popupContent-header" dangerouslySetInnerHTML={{ __html: title.text }} ></h1>
        </div>
      )}

      {description.visibility && (
        <div className="showIAN-popupContent-description showIAN-popupContent-headerWrapper">
          <p className="showIAN-popupContent-description" dangerouslySetInnerHTML={{ __html: description.text }}></p>
        </div>
      )}

      <div className="showIAN-popupContent-imageWrapper" style={{ marginTop: (title.visibility || description.visibility) ? "16px" : "50px" }}>
        <a 
          href={image.href} 
          target="_blank" 
          rel="noreferrer"
          onClick={()=>{
              // track image link click event
              trackNotificationUserEvent({
                signal_name: "notification_click_signal",
                notification_data: {
                  name: props.notification.name,
                  id: props.notification.id,
                  url: image.href,
                  label: "Image"
                }
              })
          }}
        >
          <img src={image.src} alt={image.alt} className="showIAN-popupContent-image" />
        </a>
      </div>
      <div className="showIAN-popupContent-btnWrapper show-IAN-flexCenter">
        <CTAButton 
          label={cta.label}
          href={cta.href}
          style={cta.style}
          className={"showIAN-popupContent-btn"}
          target="_blank"
          notification={props.notification}
          track
        />
      </div>
    </section>
  )
}

export default ImageWithButton