import React from "react";
import styled from "styled-components";
import { useInAppNotificationContext } from "../../context/InAppNotificationContext";

const CTAButton = ({
  label,
  href,
  className,
  style,
  target,
  track,
  notification,
  trackNotificationUserEvent
}) => {
  const context = useInAppNotificationContext();
  return (
    <Wrapper
      href={href || ""}
      className={className || ""}
      style={style}
      target={target || "_blank"}
      rel="noreferrer"
      onClick={(e) => {
        if (track && notification) {
          let _trackNotificationUserEvent = trackNotificationUserEvent || context.trackNotificationUserEvent
          _trackNotificationUserEvent({
            signal_name: "notification_click_signal",
            notification_data: {
              name: notification.name,
              id: notification.id,
              url: href,
              label: label
            }
          })
        }
      }}
    >
      {label}
    </Wrapper>
  );
};

const Wrapper = styled.a``;

export default CTAButton;
