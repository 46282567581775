export const reservedAttributeKey = [
  "email",
  "email_id",
  "os",
  "device",
  "country",
];

export const protectedPostStyles = `

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  :host {
    color: #000;
    line-height: initial;
    font-size: 18px;
    letter-spacing: 0;
    font-family: PT Sans, Arial, Helvetica, sans-serif;
  }

  span, p, h1, h2, h3, h4, h5, h6, li, a, strong, em, blockquote, pre, code {
    color: inherit;
    font-family: inherit;
    line-height: inherit;
    letter-spacing: inherit;
  }

  pre, code{
    font-family: monospace;
  }
        
 table,  td,  tr {
  border: none !important;
  outline: none !important;
}

ol, ol li {
  list-style-type: none !important;
}

ul, ul li {
  list-style-type: none !important;
}

a[href=""] {
  pointer-events: none;
  cursor: default;
}

@media screen and (max-width: 560px) {

  :host {
    font-size: 14px;
  }
    
   .strip-1_img-wrapper,
   .strip-2_img-wrapper {
    display: inline !important;
  }

   .strip-1_content-wrapper,
   .strip-2_content-wrapper {
    display: inline !important;
  }
   .btn-1_wrapper {
    font-size: 12px !important;
    /* line-height: 22px !important; */
  }
   .strip-3_img-wrapper,
   .strip-4_img-wrapper,
   .strip-5_img-wrapper,
   .strip-4_container {
    width: 100% !important;
  }
   .strip-4_description,
   .strip-4_title {
    width: 100% !important;
  }
   .strip-1_container,
   .strip-4_container,
   .strip-5_container {
    padding: 0px !important;
  }
   .strip-2_container,
   .strip-3_container {
    padding: 0 10px 6.5px !important;
  }
   .strip-2_content-wrapper {
    padding-bottom: 0 !important;
  }
   .strip-1_description {
    margin-bottom: 0px !important;
  }
   .strip__fs-18 {
    font-size: 14px !important;
  }
   .strip__fs-21 {
    font-size: 16px !important;
  }
   .strip__fs-27 {
    font-size: 20px !important;
  }
   .strip-1_img-wrapper,
   .strip-1_content-wrapper,
   .strip-2_content-wrapper,
   .strip-2_img-wrapper {
    padding: 0 !important;
  }
   .strip-1_title,
   .strip-2_title {
    margin-top: 12px;
    margin-bottom: 2px;
  }
   .strip-3_title {
    margin-top: 12px;
  }
   .strip-2_description {
    margin-bottom: 14px !important;
  }
   .strip-3_img-wrapper {
    margin-top: 14px !important;
    margin-bottom: 0 !important;
  }
   .strip-4_img-wrapper,
   .strip-5_img-wrapper {
    margin-bottom: 14px !important;
    margin-top: 0 !important;
  }
   pre {
    padding: 6px 14px !important;
    font-size: 12px !important;
  }
   p {
    font-size: 14px !important;
  }

   p span{
    font-size: 14px !important;
  }

   ol span,  ul span{
    font-size: 14px !important;
    line-height: 20px !important;
  }
    
  .listitem-unordered_wr {
    margin-right: 8px !important;
  }
    
  .listitem-style_unordered {
    font-size: 24px !important;
    line-height: 12px !important;
  }

   a {
    font-size: 14px !important;
  }
   h1 {
    font-size: 24px !important;
    line-height: 26px;
    margin: 12px 0 !important;
  }
   h2 {
    font-size: 22px !important;
    margin: 10px 0 !important;
  }
   h3 {
    font-size: 20px !important;
    margin: 10px 0 !important;
  }
   h4 {
    font-size: 18px !important;
    margin: 8px 0 !important;
  }
   h5 {
    font-size: 16px !important;
    margin: 6px 0 !important;
  }
   h6 {
    font-size: 14px !important;
    margin: 6px 0 !important;
  }

  .para-1_indent, .listitem-1_indent {
    padding-left: 10px !important;
  }
  
  .para-2_indent, .listitem-2_indent {
    padding-left: 20px !important;
  }
  
  .para-3_indent, .listitem-3_indent {
    padding-left: 30px !important;
  }
  
  .para-4_indent, .listitem-4_indent {
    padding-left: 40px  !important;
  }
}
`;