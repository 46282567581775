import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import detectUrlChange from 'detect-url-change';

import Modal from "./component/modal/Modal";
import ImageWithButton from "./component/Popups/ImageWithButton";
import TextWithButton from "./component/Popups/TextWithButton";
import VideoWithButton from "./component/Popups/VideoWithButton";
import Banner from "./component/Banners/Banner.js";

import { detectTargetDom, getLocalStorageItem, handleNotification, manageDisplayedNotification, getNotificationUniqueId, setLocalStorageItem, splitUrl, getUserTrackingIds, generateUId, addUserId, getRulesAndCondition, validateGroup, dateComparison, addDate, normalizeDate } from "./config/utils";
import { customStyles, DISPLAYED_NOTIFICATIONS, IAN_FREQUENCY, IAN_USER_ACCESS_DURATION, INTERACTION_TIMERS, LS_CACHE_TIME, LS_EXPIRY_TIME, SESSION_META_INFO, SESSION_NOTIFICATION_VALIDATIONS, SESSION_NOTIFICATIONS_LIST } from "./static";

import { getNotificationPaths, validateUserNotification } from "./Api";
import { useInAppNotificationContext } from "./context/InAppNotificationContext";
import config from "./config/app.json";

import "./App.css";
import Post from "./component/Popups/Post";
import { executeContactInfoUpdate } from ".";

const META_INFO = config['METAINFO'][config['METAINFO']['current']]

function App() {
  // context
  const { trackNotificationUserEvent, globalMetaInfo, globalUserTrackingIds, globalEmbedData } = useInAppNotificationContext()

  // states
  const [popupNotificationData, setPopupNotificationData] = useState(false);
  const [bannerData, setBannerData] = useState(null)
  const [notificationList, setNotificationList] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isNotificationBarOpened, setIsNotificationBarOpened] = useState(false);
  const [metaInfo, setMetaInfo] = globalMetaInfo
  const [userTrackingIds, setUserTrackingIds] = globalUserTrackingIds
  const [embedData, setEmbedData] = globalEmbedData


  // refs
  const popupNotificationQueue = useRef([])
  const bannerNotificationQueue = useRef([])
  const interactionTimers = useRef(getLocalStorageItem(INTERACTION_TIMERS) || {})
  const localDisplayedNotifications = useRef([])
  const intersectionObservers = useRef({})
  const localStorageCacheTime = useRef(getLocalStorageItem("LS_CACHE_TIME") || null)


  // store local user interacted times
  useEffect(() => {
    const handleBeforeUnload = () => {

      // stop all the active timers and update new reached time
      Object
      .entries(interactionTimers.current) 
      .forEach(([key, value])=>{

        // stop timers - update current reached time
        if(interactionTimers.current[key].active){
          interactionTimers.current[key].reachedTime = new Date(Date.now())
          interactionTimers.current[key].active = false
        }

        // session false - delete timers
        if(!interactionTimers.current[key].session){
          delete interactionTimers.current[key]
        }
      })
      setLocalStorageItem(INTERACTION_TIMERS, interactionTimers.current)

      // update local storage - cache time
      if(localStorageCacheTime){
        localStorageCacheTime.current.reachedTime = new Date(Date.now())
        setLocalStorageItem(LS_CACHE_TIME, localStorageCacheTime.current , "session")
      }

    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

    // useEffect - local storage expiry handler
    useEffect(()=>{

      // handler function
      const lsCacheHandler = ()=>{

        const lsCacheTime = getLocalStorageItem(LS_CACHE_TIME, "session")

        // expiry time
        let timeoutTime = LS_EXPIRY_TIME ;
        
        // add - expiry time
        if(lsCacheTime){
          const { endTime, reachedTime } = lsCacheTime
          timeoutTime = new Date(endTime).getTime() - new Date(reachedTime).getTime()
    
          localStorageCacheTime.current = {
            endTime : new Date(new Date(Date.now()).getTime() + timeoutTime),
            reachedTime: new Date(Date.now())
          }
        }

        // setTimeout 
        setTimeout(()=>{

          // reset
          setLocalStorageItem(SESSION_NOTIFICATIONS_LIST, null, "session")
          setLocalStorageItem(SESSION_NOTIFICATION_VALIDATIONS, {}, "session")
          setLocalStorageItem(LS_CACHE_TIME, null, "session")
          localStorageCacheTime.current = null

          // start - next cycle
          lsCacheHandler()
        }, timeoutTime)
    
        if(!lsCacheTime){
          localStorageCacheTime.current =  {
            endTime: new Date(new Date(Date.now()).getTime() + timeoutTime),
            reachedTime: new Date(Date.now())
          }
        }
      }

      // initiate - local storage expiry handler
      lsCacheHandler()
    
    },[])

  // remove non-session timers 
  useEffect(() => {
    Object
    .entries(interactionTimers.current) 
    .forEach(([key, value])=>{
      // session false - delete timers
      if(!interactionTimers.current[key].session){
        delete interactionTimers.current[key]
      }
    })
    setLocalStorageItem(INTERACTION_TIMERS, interactionTimers.current)
  }, []);


  // remove trigger notification from notification list
  const removeClosedTriggerNotification = (currentNotification)=>{
    if(!currentNotification?.trigger_data?.trigger_event) return;

    if(notificationList){
      let _notificationList = [...notificationList]
      _notificationList = _notificationList.filter((notification)=>{
        return notification.id !== currentNotification.id
      })
      setNotificationList(_notificationList)
    }
  } 

  // close modal
  const closeModal = () => {

    // track close event - popup
    trackNotificationUserEvent({
      signal_name: "notification_close_signal",
      notification_data: {
        name: popupNotificationData.name,
        id: popupNotificationData.id,
      }
    })

    // handle popup frequency
    handleInAppFrequency(popupNotificationData)

    // remove closed trigger notification
    removeClosedTriggerNotification(popupNotificationData)

    setIsOpen(false);
    document.body.style.overflow = "";
    setPopupNotificationData(null)
    popupNotificationQueue.current = popupNotificationQueue.current.filter(({ id })=>{
      return popupNotificationData.id !== id
    })

    manageDisplayedNotification(popupNotificationData, localDisplayedNotifications)

    if(popupNotificationQueue.current[0]){
      setTimeout(()=>{
        if(validateIndividualNotification(popupNotificationQueue.current[0], { isValidated: true })){
          setPopupNotificationData(popupNotificationQueue.current[0])
          setIsOpen(true);
        }
      }, 1100)
    }
  };

  // close notification bar
  const closeNotificationBar = () => {

    // track close event - banner
    trackNotificationUserEvent({
      signal_name: "notification_close_signal",
      notification_data: {
        name: bannerData.name,
        id: bannerData.id,
      }
    })

    // handle banner frequency
    handleInAppFrequency(bannerData)

    // remove closed trigger notification
    removeClosedTriggerNotification(bannerData)

    setIsNotificationBarOpened(false);
    setBannerData(null)

    bannerNotificationQueue.current = bannerNotificationQueue.current.filter(({ id })=>{
      return bannerData.id !== id
    })

    manageDisplayedNotification(bannerData, localDisplayedNotifications)

    if(bannerNotificationQueue.current[0]){
      setTimeout(()=>{
        if(validateIndividualNotification(bannerNotificationQueue.current[0], { isValidated: true })){
          setBannerData(bannerNotificationQueue.current[0])
          setIsNotificationBarOpened(true);
        }
      },1100)
    }

  };


  // validate a notification
  const validateIndividualNotification = async (notification, options)=>{

    // display once - array
    const displayedNotificationLs = getLocalStorageItem(DISPLAYED_NOTIFICATIONS) || []
  
    let displayed = displayedNotificationLs.includes(getNotificationUniqueId(notification)) || localDisplayedNotifications.current.includes(getNotificationUniqueId(notification))
    
    if(!notification.session){
      displayed = false
    }

    const href = window.location.href
    const url = splitUrl(href)

    // validated user id
    const validatedUserNid = generateUId(notification)

    // match url
    let matchUrl = false
    let _config = null

    if(notification.path_queryset){
      const {validation, config} = validatePathQuery(notification)
      matchUrl = validation
      _config = config
    }else{
      // empty path
      if(!notification.paths || notification.paths.length === 0){
        matchUrl = true
      }

      // all paths
      else if(notification.paths.includes('*')){
        matchUrl = true
      }

      // match - pathname
      else if(notification.paths.includes(url.path)){
        matchUrl = true
      }

      // single slash - match domain 
      else if(notification.paths.includes('/')){
        matchUrl = (window.origin === href.slice(0, -1))
      }
    }

    const isActive = notification.active

    // is notification expired
    const expiryDate =  new Date(notification.expiry_date)
    const currentDate = new Date()

    let isExpired = false
    if(notification.expiry_date){
      isExpired = normalizeDate(currentDate) > normalizeDate(expiryDate)
    }

    // is valid start date
    let isValidStartDate = false;

    if(notification.hasOwnProperty("start_date")){
      const startDate =  new Date(notification.start_date)
      isValidStartDate = normalizeDate(currentDate) >= normalizeDate(startDate)
    }else{ // handle old notification data
      isValidStartDate = true
    }


    // is notification validated
    let isNotificationValidated = false;

    // validate user notification to display
    let isValidUserNotification = true

    // session validated user and notifications
    const validatedNotifications = getLocalStorageItem(SESSION_NOTIFICATION_VALIDATIONS, "session")

    if(validatedNotifications && validatedNotifications.hasOwnProperty(validatedUserNid)){
      isValidUserNotification = validatedNotifications[validatedUserNid]
      isNotificationValidated = true
    }
    
    if((embedData && embedData.email ) && ((((!displayed && matchUrl) && !(options && options.isValidated))) && (!isNotificationValidated && isActive))){
      try{
        const response = await validateUserNotification({
          owner_account_id: embedData.owner_account_id,
          email: embedData.email,
          rule_queryset: notification.rule_queryset,
          id: notification.id,
        })

        if(response && response.status === 200){
          const data = await response.json()

          if(data && data.hasOwnProperty("valid_user")){
            isValidUserNotification = data.valid_user

            const _validatedNotifications = getLocalStorageItem(SESSION_NOTIFICATION_VALIDATIONS, "session") || {}
            _validatedNotifications[validatedUserNid] = data.valid_user
            // validated notifications - store in session storage 
            setLocalStorageItem(
              SESSION_NOTIFICATION_VALIDATIONS,
              _validatedNotifications,
              "session"
            )
          }else{
            isValidUserNotification = false
          }

        }else{
          isValidUserNotification = false
        }

      }catch(error){
        // api failure
        isValidUserNotification = false
        console.log(error)
      }
    }

    // validate popup frequency | user notification access
    const isValidToDisplay = validateIANFrequency(notification)
    const isValidNotificationAccess = checkUserValidAccessDuration(notification)


    const booleans = [isValidNotificationAccess, isValidUserNotification, matchUrl, !displayed , isActive, !isExpired , isValidStartDate, isValidToDisplay]
    return {
      validation: booleans.every((boolean)=> boolean),
      config: _config
    }
  }

  // initiate in-app notifications
  useEffect(() => {
    let initiated = false;
    let finalNotificationList = [];

    // get notifications
    const getNotifications = async (config)=>{
      if(!config.owner_account_id) return;

      // logged-in scenario email
      setEmbedData(config)

      setUserTrackingIds(getUserTrackingIds(config.owner_account_id))

      // session - notification and meta info data
      const localNotificationList = getLocalStorageItem(SESSION_NOTIFICATIONS_LIST, "session");
      const localMetaInfo = getLocalStorageItem(SESSION_META_INFO, "session");

      // session meta info 
      if(localMetaInfo){
        setMetaInfo(localMetaInfo)
      }

      // session notification data
      if(localNotificationList){
        const _notificationList = addUserId(localNotificationList, config)
        // setNotificationList(_notificationList);
        setLocalStorageItem(
          SESSION_NOTIFICATIONS_LIST, 
          _notificationList,
          "session"
        )
        finalNotificationList = _notificationList
      }

      // get meta info
      if(!localMetaInfo){
        try{
          const data = await fetch(META_INFO).then(response => response.json())
          if(data.success){
            delete data.success
            setMetaInfo(data)
            setLocalStorageItem(
              SESSION_META_INFO, 
              data, 
              "session"
            )
          }else{
            return;
          }
     
        }catch(error){
          console.log(error)
          // on meta error
          return ;
        }
      }

      // update contact info
      try{
        const meta_info = getLocalStorageItem(SESSION_META_INFO, "session");
        const response = await executeContactInfoUpdate({
          owner_account_id: config.owner_account_id,
          email: config.email,
          attributes: config.attributes,
          meta_info : meta_info
        })

        if(response && response.status !== 200){
          console.log("Error/contactInfoUpdate", response)
        }
 
      }catch(err){
        console.log("Error/contactInfoUpdate", err)
      }

      // get trigger notifications
      if(config.email){
        try {
          const triggerNotifications = await getNotificationPaths("trigger", {
            owner_account_id: config.owner_account_id,
            email: config.email, 
          })
          
          if(triggerNotifications?.data?.data?.length){
            let _notificationList = addUserId(triggerNotifications.data.data, config)
            finalNotificationList = [..._notificationList, ...finalNotificationList]
          }
  
        } catch (error) {
          console.log(error);
        }
      }


      // check session notification list api data
      if(localNotificationList) {
        setNotificationList(finalNotificationList);
        return
      };

      // get all notifications related to domain
      try {
        const allDomainNotifications = await getNotificationPaths("all", {
          owner_account_id: config.owner_account_id,
          domain: config.custom_domain || window.location.origin
        })

        if(allDomainNotifications && allDomainNotifications.data){
          const _notificationList = addUserId(allDomainNotifications.data, config)
          let withTriggerNotificationList = [...finalNotificationList, ..._notificationList]
          setNotificationList(withTriggerNotificationList);
          setLocalStorageItem(
            SESSION_NOTIFICATIONS_LIST, 
            _notificationList,
            "session"
          )
        }

      } catch (error) {
        console.log(error);
      }
    }
    
    // initiate notification
    if(typeof(window.ShowIAN) === "object"){
      getNotifications(window.ShowIAN)
    }else{
      Object.defineProperty(window, "ShowIAN", {
        set: function(value){
          if(typeof(value) === "object" && !initiated){
            getNotifications(value)
            initiated = true
          }
        }
      })
    }

  }, []);


  // handle user seen notifications
  const userEventSeen = (notification)=>{
    // notification seen
    trackNotificationUserEvent({
      signal_name: "notification_seen_signal",
      notification_data: {
        name: notification.name,
        id: notification.id,
      }
    })
  }
  

  // notification handler
  const notificationScheduler = (notificationData)=>{

    const { rule_engine: notificationConfig, notification } = notificationData;
    const notificationType = notification.notification_type;

    // set notification in queue
    const setNotification = ()=>{
      // add current path
      notificationData.notificationPath = splitUrl(window.location.href).path

      if(notification.notification_type === 1){
        popupNotificationQueue.current.push(notificationData)
      }

      if(notification.notification_type === 2){
        bannerNotificationQueue.current.push(notificationData)
      }
      
      const newPopupNotification = popupNotificationQueue.current[0]
      const newBannerNotification = bannerNotificationQueue.current[0]

      if(newPopupNotification){
        const { notification } = newPopupNotification
        // open notification popup modal UI
        if(notification.notification_type === 1){
          setIsOpen(true); 
          setPopupNotificationData(newPopupNotification);
        }
      }

      if(newBannerNotification){
        const { notification } = newBannerNotification
        // open notification bar UI
        if(notification.notification_type === 2){
          setIsNotificationBarOpened(true);
          setBannerData(newBannerNotification);
        }
      }
    }

    // notification init
    let interactionTime = notificationConfig.interaction_time;
    const notificationConfigType = notificationConfig.rule_engine_type;
    const scrollTargetConfig = notificationConfig.scroll_target_config;

    // variables
    let timerId;
    
    // handlers
    // onload
    const handleOnload = (event) => {
      // setNotification()
    };

    // init
    const init = async () => {

      // notification types
      // on target page load to show notification --> type 1
      if (notificationConfigType === 1) {
        // handleOnload();
        handleTimer()
      }

      function handleTimer (){

        if (typeof interactionTime === "number") {

          // saved interaction time
          if(interactionTimers.current[getNotificationUniqueId(notificationData)]){
            const { endTime, reachedTime, timerId } = interactionTimers.current[getNotificationUniqueId(notificationData)]
            clearTimeout(timerId)
            interactionTime = new Date(endTime).getTime() - new Date(reachedTime).getTime()

            interactionTimers.current[getNotificationUniqueId(notificationData)].active = true
            interactionTimers.current[getNotificationUniqueId(notificationData)].endTime = new Date(new Date(Date.now()).getTime() + interactionTime)
            interactionTimers.current[getNotificationUniqueId(notificationData)].reachedTime = new Date(Date.now())

          }

          // user interaction set timer and id
          timerId = setTimeout(()=>{
            // clean up timers
            delete interactionTimers.current[getNotificationUniqueId(notificationData)]
            setLocalStorageItem(INTERACTION_TIMERS, interactionTimers.current)
            
            // popup
            handleNotification(1, notificationType, () => {
              setNotification()
            });

            // notification bar
            handleNotification(2, notificationType, () => {
              setNotification()
            });

          }, interactionTime)

          // create timer object
          if(!interactionTimers.current[getNotificationUniqueId(notificationData)]){
            interactionTimers.current[getNotificationUniqueId(notificationData)] = {
              timerId: timerId,
              endTime: new Date(new Date(Date.now()).getTime() + interactionTime),
              reachedTime: new Date(Date.now()),
              nid: notificationData.id,
              sid: notificationData.sid || null,
              active : true,
              session: notificationData.session
            }
            
          }

          interactionTimers.current[getNotificationUniqueId(notificationData)].timerId = timerId
        }
      }
      // interaction time to show notification --> type 2
      if (notificationConfigType === 2) {
        handleTimer()
      }

      // target scroll element to show notification  --> type 3
      if (notificationConfigType === 3) {

        detectTargetDom(notificationConfig.scroll_target_select_query , async (detected) => {
          let scrollTarget = document.querySelector(notificationConfig.scroll_target_select_query)

          // remove existing observer
          if(intersectionObservers.current[getNotificationUniqueId(notificationData)]){
            const { intersectionObserver, scrollTarget } = intersectionObservers.current[getNotificationUniqueId(notificationData)] 
            intersectionObserver.unobserve(scrollTarget)
          }
    
          // validate again before attach observer
          if(!await validateIndividualNotification(notificationData, { isValidated: true })){
            return ;
          }

          if (detected) {
            const intersectionObserver = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                // target intersecting
                if (entry.isIntersecting) {
  
                  // popup
                  handleNotification(1, notificationType, () => {
                    // document.body.style.overflow = "hidden";
                    // setNotification()
                    handleTimer()
                    intersectionObserver.unobserve(scrollTarget);
                  });
  
                  // notification bar
                  handleNotification(2, notificationType, () => {
                    // setNotification()
                    handleTimer()
                    intersectionObserver.unobserve(scrollTarget);
                  });
                  return;
                }

              });
            }, scrollTargetConfig);
  
            intersectionObserver.observe(scrollTarget);

            // add observer ref
            intersectionObservers.current[getNotificationUniqueId(notificationData)] = { intersectionObserver, scrollTarget }
          }
        });

      }
    };

    const onDocumentReady = ()=>{
      // initiate notification on onload
      detectTargetDom(notificationConfig.target_onload_select_query || "body" , (detected) => {
        if (detected) {
          init();
        }
      });
    }    

    // document
    if(document.readyState === "complete"){
      onDocumentReady()
    }else{
      window.addEventListener('load', ()=>{
        onDocumentReady()
      })
    }

  }


  // handle in-app notification frequency
  const validateIANFrequency = (notification) =>{
    const ianFrequencies = getLocalStorageItem(IAN_FREQUENCY) || {}
    const nid = generateUId(notification)

    // handle old popup
    if(!notification.hasOwnProperty("frequency") || notification.frequency.count === null || notification.frequency.interval_type === null){
      return true
    }

    // frequency count value as zero
    if(notification.frequency.count === 0 || notification.frequency.interval_type === 0){
      return false
    }

    // notification Id if not present in IAN_FREQUENCY object.
    if(!ianFrequencies[nid]){
      return true
    }

    // day cycle - type number 1
    if(notification.frequency.interval_type === 1 && ianFrequencies[nid]){
      let interval_value = notification.frequency.interval_value
      const frequencyCount = (ianFrequencies[nid].count < notification.frequency.count)

      if(!notification.frequency.hasOwnProperty("interval_value")){
        interval_value = 1
      }

      const isResetDate = dateComparison(new Date(Date.now()), addDate(ianFrequencies[nid].seen, interval_value), ">=")
      const isLessThan = dateComparison(new Date(Date.now()), addDate(ianFrequencies[nid].seen, interval_value), "<")
      
      // check frequency count
      if(frequencyCount && isLessThan){
        return true
      }

      // on next interval
      if(isResetDate){
        ianFrequencies[nid].count = 0
        ianFrequencies[nid].seen = new Date(Date.now())
        setLocalStorageItem(IAN_FREQUENCY, ianFrequencies)
      }
      
      return isResetDate
    }

    return true
  }

  // handle in-app notification frequency
  const handleInAppFrequency = (notification)=>{

    // handle old popup | handle value as zero 
    if(!notification.hasOwnProperty("frequency")) return;
    
    // frequency count value as zero
    if(!notification.frequency.count  || !notification.frequency.interval_type ) return;

    const ianFrequencies= getLocalStorageItem(IAN_FREQUENCY) || {}
    const nid = generateUId(notification)

    // every day 
    if(notification.frequency.interval_type === 1 ){
      const currentDate = new Date(Date.now())
      const count = !ianFrequencies[nid] ? 1 : ianFrequencies[nid].count + 1

      if(ianFrequencies[nid]){
        ianFrequencies[nid] = {
          ...ianFrequencies[nid],
          count: count,
        }
      }else{
        ianFrequencies[nid] = {
          count: count,
          seen: currentDate
        }
      }

      
      setLocalStorageItem(IAN_FREQUENCY, ianFrequencies)
    }

  }

  const checkUserValidAccessDuration = (notification)=>{
    const userAccessDuration = getLocalStorageItem(IAN_USER_ACCESS_DURATION) || {}
    const nid = generateUId(notification)

    // handle old popup | initial check 
    if(!notification.hasOwnProperty("user_access_duration") || !userAccessDuration[nid] || notification.user_access_duration == null){
      return true
    }

    // value as zero - since user seen the popup once user cant get the popup again.
    if(notification.user_access_duration === 0){
      return false
    }

    // 24 hours
    const aDay = (1000 * 60 * 60 * 24)
    const currentDate = new Date(Date.now())
    const expiryDate = new Date(new Date(userAccessDuration[nid]).getTime() + notification.user_access_duration * aDay);

    // current date less than user seen popup date
    return currentDate <= expiryDate
  }

  // set when user seen the popup
  const setUserFirstTime = (notification)=>{
    const usersAccessDuration = getLocalStorageItem(IAN_USER_ACCESS_DURATION) || {}
    const nid = generateUId(notification)

    // handle old popups | already user user seen the popup
    if(!notification.hasOwnProperty("user_access_duration") || usersAccessDuration[nid]){
      return ;
    }

    if(notification.user_access_duration !== null){
      usersAccessDuration[nid] = new Date(Date.now())
      setLocalStorageItem(IAN_USER_ACCESS_DURATION, usersAccessDuration)
    }
  }

  // useEffects - user seen
  useEffect(()=>{
    if(bannerData && bannerData.id){
      userEventSeen(bannerData)
      setUserFirstTime(bannerData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[bannerData?.id])

  useEffect(()=>{
    if(popupNotificationData && popupNotificationData.id){
      userEventSeen(popupNotificationData)
      setUserFirstTime(popupNotificationData)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[popupNotificationData?.id])

  /* validate path query */
  const validatePathQuery = (notification)=>{
    const path_query = JSON.parse(notification.path_queryset)

    const { rules: rulesGroup, condition: rootCondition } = getRulesAndCondition(path_query);

    // notification config from path query data
    const config = {
      interaction_time: 0,
      rule_engine_type: 2, // by default 2. 
      scroll_target_config: { threshold: 0, rootMargin: "0px" },
      scroll_target_select_query: "",
      target_onload_select_query: "",
    };

    let validation = false;
    
    // root - and condition validation
    if (rootCondition === "search__&") {
      validation = rulesGroup.every((group, index) => {
        const { condition, rules } = getRulesAndCondition(group);

        // or - group validation
        if (condition === "search__|") {
          return rules.some((rule) => validateGroup({rule, config, embedData, index}));
        }

        // and - group validation
        if (condition === "search__&") {
          return rules.every((rule) => validateGroup({rule, config, embedData, index}));
        }

        return false
      });
    }

    // root - or condition validation
    if (rootCondition === "search__|") {
      validation = rulesGroup.some((group, index) => {
        const { condition, rules } = getRulesAndCondition(group);

        // or - group validation
        if (condition === "search__|") {
          return rules.some((rule) => validateGroup({rule, config, embedData, index}));
        }

        // and - group validation
        if (condition === "search__&") {
          return rules.every((rule) => validateGroup({rule, config, embedData, index}));
        }
        
        return false
      });
    }

    return {
      config,
      validation
    }
  }

  // display notification useEffect
  useEffect(() => {
    if (!notificationList) return;

    let activeNotifications = []

    // url change
    const handleLocationChange = async () => {
      // reset active notification on route change
      activeNotifications = []

      for (const notification of notificationList){
        // validate and match individual paths
        const {validation, config } = await validateIndividualNotification(notification)
        if(validation){
          if(config){
            notificationScheduler({
              ...notification,
              rule_engine: config
            })
          }else{
            notificationScheduler(notification)
          }

          activeNotifications.push(getNotificationUniqueId(notification))
        }
      }

      // remove intersection observer - in-active url
      Object
      .entries(intersectionObservers.current)
      .forEach(([key, value])=>{
          if(!activeNotifications.includes(key) && intersectionObservers.current[key]){
            value.intersectionObserver.unobserve(value.scrollTarget)
            delete intersectionObservers.current[key]
          }
      })

      // paused user interacted timer - in-active url
      Object
      .entries(interactionTimers.current) 
      .forEach(([key, value])=>{
        if(!activeNotifications.includes(getNotificationUniqueId({id: value.nid, sid: value.sid}))){
          if(interactionTimers.current[key].active){
            clearTimeout(value.timerId)
            interactionTimers.current[key].reachedTime = new Date(Date.now())
            interactionTimers.current[key].active = false
          }
        }
      })
    };

    // initial notification check
    let initiated = false;

    let urlChangeHandler = (newUrl) => {
      if(initiated){
        handleLocationChange()
      }
    }

    // url change listener
    detectUrlChange.on('change', urlChangeHandler);

    handleLocationChange()
    setTimeout(()=>{initiated = true},100)

    return ()=>{
      detectUrlChange.off('change', urlChangeHandler)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationList]);

  useEffect(() => {
    if (!bannerData) return;
    const { notification } = bannerData;

    if(notification && notification.notification_type !== 2){
      return
    }

    const body = document.querySelector("body");
    const navbar = document.createElement("nav");
    navbar.className = "showIAN-bannerNotification";

    ReactDOM.render(
      <>
        <Banner
          notification={bannerData}
          closeNotificationBar={closeNotificationBar}
          isNotificationBarOpened={isNotificationBarOpened}
          trackNotificationUserEvent={trackNotificationUserEvent}
        />
      </>,
      navbar
    );

    if (body.firstElementChild) {
      body.insertBefore(navbar, body.firstElementChild);
    }

    return () => {
      ReactDOM.unmountComponentAtNode(navbar);
      document.body.removeChild(navbar);
    };
  }, [bannerData, isNotificationBarOpened]);


  // handle notification container
  if (!popupNotificationData) {
    return null;
  }

  const { notification } = popupNotificationData;

  return (
    <div className="showIAN-main-container">
      <Modal
        notification={popupNotificationData}
        isOpen={modalIsOpen}
        style={customStyles}
        closeModal={closeModal}
        closeTimeoutMS={200}
        className={"showIAN-modalAnimation-slidingFromBottom showIAN-modalMain-container showIAN-blockContainer-reset"}
        overlayClassName="showIAN-overlay-container"
      >
        {notification.category === 1 && <ImageWithButton notification={popupNotificationData} />}
        {notification.category === 2 && <TextWithButton notification={popupNotificationData} />}
        {notification.category === 3 && <VideoWithButton notification={popupNotificationData} />}
        {notification.category === 4 && <Post notification={popupNotificationData} />}
      </Modal>
    </div>
  );
}

export default App;
