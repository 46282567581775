import React from "react";

const SWCrossIcon = ({ fillA, fillB }) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill={fillA || "#000000"} style={{cursor: "pointer"}}>
      <g transform="translate(-6106.125 -4938.725)">
        <circle className="a" opacity="1" isolation="isolate" cx="8" cy="8" r="8" transform="translate(6106.125 4938.725)"></circle>
        <path className="b" fill={ fillB || "#ffffff"} d="M6116.679,4945.324l-1.373,1.372,1.335,1.335a.386.386,0,0,1,0,.544l-.675.675a.385.385,0,0,1-.544,0l-1.335-1.335-1.363,1.363a.382.382,0,0,1-.542,0l0,0-.609-.608a.386.386,0,0,1,0-.545l1.363-1.363-1.335-1.334a.387.387,0,0,1,0-.545l.675-.675a.386.386,0,0,1,.545,0l1.334,1.335,1.373-1.372a.384.384,0,0,1,.542,0l0,0,.609.608A.387.387,0,0,1,6116.679,4945.324Z"></path>
      </g>
    </svg>
  )
}


export default SWCrossIcon;