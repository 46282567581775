// modal overlay and content
export const customStyles = {
  overlay: {
    zIndex: 999999,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#0000009e",
    overflow: "scroll",
    display: "grid",
    placeContent: "center"
  },
  content: {
    position: "relative",
    maxWidth: "660px",
    height: "fit-content",
    margin: "auto",
    overflow: "auto",
    outline: "none",
    padding: 0,
    border: "none",
    opacity: 0.3
  }
};


//  local storage keys
export const DISPLAYED_NOTIFICATIONS = "DISPLAYED_NOTIFICATIONS"
export const INTERACTION_TIMERS = "INTERACTION_TIMERS"
export const IAN_FREQUENCY = "IAN_FREQUENCY"
export const IAN_USER_ACCESS_DURATION = "IAN_USER_ACCESS_DURATION"

// session storage keys
export const SESSION_NOTIFICATIONS_LIST = "SESSION_NOTIFICATIONS_LIST"
export const SESSION_NOTIFICATION_VALIDATIONS = "SESSION_NOTIFICATION_VALIDATIONS"
export const SESSION_META_INFO = 'SESSION_META_INFO';
export const LS_CACHE_TIME = "LS_CACHE_TIME";


// local storage cache expiry time - ms * s * m
export const LS_EXPIRY_TIME = 1000 * 60 * 10