import React from "react";
import "./VideoWithButton.css";
import CTAButton from "../Buttons/CTAButton";

const VideoWithButton = (props) => {
  const { title, description, video, cta } = props.notification.notification

  return (
    <section className="showIAN-videoWithButton-popupContainer">

      {title.visibility && (
        <div className="showIAN-popupContent-headerWrapper" >
          <h1 className="showIAN-popupContent-header" dangerouslySetInnerHTML={{ __html: title.text }} ></h1>
        </div>
      )}

      {description.visibility && (
        <div className="showIAN-popupContent-descriptionWrapper">
          <p className="showIAN-popupContent-description" dangerouslySetInnerHTML={{ __html: description.text }}></p>
        </div>
      )}

      <div style={{ marginTop: (title.visibility || description.visibility) ? "16px" : "50px" }}>
        <div dangerouslySetInnerHTML={{ __html: video.script }}></div>
      </div>

      <div className="showIAN-popupContent-btnWrapper show-IAN-flexCenter">
      <CTAButton 
          label={cta.label}
          href={cta.href}
          style={cta.style}
          className={"showIAN-popupContent-btn"}
          target="_blank"
          notification={props.notification}
          track
        />
      </div>

    </section>
  )
}

export default VideoWithButton