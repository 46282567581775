// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.banner-ctaBtn{
  display: inline-block;
  padding: 10px 20px;
  border-radius: 4px;
  background-color: #111 !important;
  border: none;

  color: #fff !important;
  font-size: 16px !important;
  line-height: 1 ;
  transition: background-color 0.3s ease;
  font-weight: bold;
}

.banner-ctaBtn:hover{
  background-color: #8235c9;
}`, "",{"version":3,"sources":["webpack://./src/component/Banners/Banner.css"],"names":[],"mappings":";AACA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,kBAAkB;EAClB,iCAAiC;EACjC,YAAY;;EAEZ,sBAAsB;EACtB,0BAA0B;EAC1B,eAAe;EACf,sCAAsC;EACtC,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["\n.banner-ctaBtn{\n  display: inline-block;\n  padding: 10px 20px;\n  border-radius: 4px;\n  background-color: #111 !important;\n  border: none;\n\n  color: #fff !important;\n  font-size: 16px !important;\n  line-height: 1 ;\n  transition: background-color 0.3s ease;\n  font-weight: bold;\n}\n\n.banner-ctaBtn:hover{\n  background-color: #8235c9;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
