import React from "react";
import "./TextWithButton.css";
import CTAButton from "../Buttons/CTAButton";

const TextWithButton = (props) => {
  const { title, description, cta } = props.notification.notification

  return (
    <section className="showIAN-textWithButton-popupContainer">
      <div className="showIAN-popupContent-headerWrapper" >
        <h1 className="showIAN-popupContent-header" dangerouslySetInnerHTML={{ __html: title.text }} ></h1>
      </div>

      <div className="showIAN-popupContent-descriptionWrapper">
        <p className="showIAN-popupContent-description" dangerouslySetInnerHTML={{ __html: description.text }}></p>
      </div>

      <div className=" showIAN-popupContent-btnWrapper">
      <CTAButton 
          label={cta.label}
          href={cta.href}
          style={cta.style}
          className={"showIAN-popupContent-btn"}
          target="_blank"
          notification={props.notification}
          track
        />
      </div>

    </section>
  )
}

export default TextWithButton