// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showIAN-post-popupContainer {
  padding: 0 28px 24px;
  display: block !important;
}

@media screen and (max-width: 560px) {
  .showIAN-post-popupContainer {
    padding: 0 22px 18px;
  }
}`, "",{"version":3,"sources":["webpack://./src/component/Popups/Post.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,yBAAyB;AAC3B;;AAEA;EACE;IACE,oBAAoB;EACtB;AACF","sourcesContent":[".showIAN-post-popupContainer {\n  padding: 0 28px 24px;\n  display: block !important;\n}\n\n@media screen and (max-width: 560px) {\n  .showIAN-post-popupContainer {\n    padding: 0 22px 18px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
