import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { reservedAttributeKey } from "./config/staticData";
import reportWebVitals from './reportWebVitals';

import InAppNotificationContextProvider from './context/InAppNotificationContext';

//Packages
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import Modal from "react-modal";

//API
import { storeCustomEvent, storeCustomAttributes, updateContactInfo, getMetaInfo } from "./Api";

//Local files
import { sessionStorageTest, localStorageTest, getOs, getBrowser, getDevice } from "./config/utils";
import config from "./config/app.json";

//Static data
const METAINFO = config['METAINFO'][config['METAINFO']['current']]

/* function to track whether the session has been calculated already
   either by the ShowVFM function or the Show Player */
function handleSession() {
  let sessionCreated = false

  const SESSION_LITERAL = "show_video_session"

  if (sessionStorageTest() === true) {
    if (JSON.parse(sessionStorage.getItem(SESSION_LITERAL)) &&
      JSON.parse(sessionStorage.getItem(SESSION_LITERAL)).id)
      sessionStorage.removeItem(SESSION_LITERAL)
  }

  return {
    getSessionCreated: function () {
      return sessionCreated
    },
    setSessionCreated: function (value) {
      sessionCreated = value
    }
  }
}

export const handleSessionContext = handleSession()

/* Error messges  */
const MESSAGE = {
  VISITOR_NOT_FOUND: "Error: Visitor/Session not found",
  META_INFO: "Error: Meta Information could not be fetched",
  STORAGE: "Error: Custom event couldn't be stored, please try again later",
  SUCCESS: "Success"
}

/* storage literals */
export const STORAGE = {
  VISITOR: "show_visitor_id",
  SESSION: "show_video_session"
}

/* encode util */
const encodeString = o => btoa(o)

/* random session on each refresh */
export const getRandomSession = (uniqueId) => {
  let sessionId =
    uuidv4() +
    "-" +
    encodeString(uniqueId) +
    Math.random(0).toString(36).substr(4) +
    "-" +
    Math.random(0).toString(36).substr(6) +
    new Date().getTime().toString(36)
  return sessionId
}

/* random visitor id when not present in local storage */
export const getRandomShowVisitorId = (uniqueId) => {
  let show_visitor_id =
    uuidv4() +
    "-" +
    Math.random(0).toString(36).substr(4) +
    encodeString(uniqueId) +
    "-" +
    Math.random(0).toString(36).substr(6) +
    new Date().getTime().toString(36)

  return show_visitor_id
}

/* 
  ShowVFM function that returns function to trigger custom events
  input : clients access token
  output : function to trigger custom events
*/
window.ShowVFM = function (apiAccessToken) {
  /* information gathering for api call */
  let created_at = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
  let meta_info = null;

  let visitor_id;
  if (localStorageTest() === true) {
    if (JSON.parse(localStorage.getItem(STORAGE.VISITOR))) {
      visitor_id = JSON.parse(localStorage.getItem(STORAGE.VISITOR)).id
    } else {
      visitor_id = getRandomShowVisitorId(apiAccessToken)
    }
  } else {
    visitor_id = getRandomShowVisitorId(apiAccessToken)
  }

  let session_id;
  if (sessionStorageTest() === true) {
    if (JSON.parse(sessionStorage.getItem(STORAGE.SESSION))) {
      session_id = JSON.parse(sessionStorage.getItem(STORAGE.SESSION)).id
    } else {
      session_id = getRandomSession(apiAccessToken)
    }
  } else {
    session_id = getRandomSession(apiAccessToken)
  }

  if (localStorageTest() === true) localStorage.setItem(STORAGE.VISITOR, JSON.stringify({ id: visitor_id }));

  if (!handleSessionContext.getSessionCreated()) {
    if (sessionStorageTest() === true) sessionStorage.setItem(STORAGE.SESSION, JSON.stringify({ id: session_id }));
    handleSessionContext.setSessionCreated(true)
  }

  /* 
    function exposed to client developers for triggering customized events
    input : event object
    output : success or error message for debugging 
  */
  return function (event) {

    //error conditions
    let metaInfoError = false;
    let storeError = false;

    //visitor/session not found
    if (!visitor_id || !session_id)
      return MESSAGE.VISITOR_NOT_FOUND;

    //fetch meta info required for storing custom event
    fetch(METAINFO).then(response => response.json()).then((data) => {
      delete data.success;
      meta_info = data;

      //calls custom event api
      storeCustomEvent(event, visitor_id, session_id, meta_info, created_at, apiAccessToken).catch(err => {
        storeError = true;
      });
    }).catch(err => {
      metaInfoError = true;
    });

    //meta info call failed
    if (metaInfoError)
      return MESSAGE.META_INFO;

    //custom event call failed
    if (storeError)
      return MESSAGE.STORAGE;

    return MESSAGE.SUCCESS;
  }

}


//Fuction to manage custom attributes 
function customAttributeSignal(account_id, attributeData, reservedAttribute, created_at) {
  /* information gathering for api call */
  let meta_info = null;

  let visitor_id;
  if (localStorageTest() === true) {
    if (JSON.parse(localStorage.getItem(STORAGE.VISITOR))) {
      visitor_id = JSON.parse(localStorage.getItem(STORAGE.VISITOR)).id
    } else {
      visitor_id = getRandomShowVisitorId(account_id)
    }
  } else {
    visitor_id = getRandomShowVisitorId(account_id)
  }

  let session_id;
  if (sessionStorageTest() === true) {
    if (JSON.parse(sessionStorage.getItem(STORAGE.SESSION))) {
      session_id = JSON.parse(sessionStorage.getItem(STORAGE.SESSION)).id
    } else {
      session_id = getRandomSession(account_id)
    }
  } else {
    session_id = getRandomSession(account_id)
  }

  if (localStorageTest() === true) localStorage.setItem(STORAGE.VISITOR, JSON.stringify({ id: visitor_id }));

  if (!handleSessionContext.getSessionCreated()) {
    if (sessionStorageTest() === true) sessionStorage.setItem(STORAGE.SESSION, JSON.stringify({ id: session_id }));
    handleSessionContext.setSessionCreated(true)
  }

  //error conditions
  let metaInfoError = false;
  let attributeStoreError = false;

  //visitor/session not found
  if (!visitor_id || !session_id)
    return MESSAGE.VISITOR_NOT_FOUND;

  //fetch meta info required for storing custom event
  fetch(METAINFO).then(response => response.json()).then((data) => {
    delete data.success;
    meta_info = data;

    //calls custom event api
    storeCustomAttributes(account_id, attributeData, reservedAttribute, visitor_id, session_id, meta_info, created_at).catch(err => {
      attributeStoreError = true;
    });
  }).catch(err => {
    metaInfoError = true;
  });

  //meta info call failed
  if (metaInfoError)
    return MESSAGE.META_INFO;

  //custom event call failed
  if (attributeStoreError)
    return MESSAGE.STORAGE;

  return MESSAGE.SUCCESS;

}

/* 
 Custom attributes Handling
*/
if (window.ShowAttributes && typeof window.ShowAttributes === "object") {
  let attributeData = window.ShowAttributes
  let reservedAttribute = {}
  if ('account_id' in attributeData && Object.keys(attributeData).length > 1) {
    let created_at = format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx");
    let owner_account_id = attributeData.account_id
    delete attributeData.account_id;
    let local_storage_attribute = [{"attribute_text": attributeData, "created_at": created_at}]
    if (localStorageTest() === true) localStorage.setItem("attribute_event", JSON.stringify(local_storage_attribute));
    Object.keys(attributeData).map((attr, i) => {
      if (reservedAttributeKey.includes(attr)) {
        reservedAttribute[attr] = Object.values(attributeData)[i]
      }
    })
    Object.keys(attributeData).map((attr, i) => {
      if (reservedAttributeKey.includes(attr)) {
        delete attributeData[attr]
      }
    }
    )
    customAttributeSignal(owner_account_id, attributeData, reservedAttribute, created_at)
  }
}

// meta info 
let meta_info;

// set meta info
const setMetaInfo = async ()=>{
  try{
    const response = await getMetaInfo()

    if(response.success){
      delete response.success
      meta_info = response
    }else{
      console.log('Error/metaInfo', response)
    }

  }catch(err){
    console.log('Error/metaInfo', err)
  }
}


// update contact info api call
export const executeContactInfoUpdate = async (config)=>{
  if(!config.owner_account_id || !config.email ) return;

  // set new meta info
  if(!config.meta_info && !meta_info){
    await setMetaInfo()
  }

  // api payload
  let payload = {
    owner_account_id: config.owner_account_id,
    email: config.email,
  }

  // update meta info
  if(meta_info || config.meta_info){
    payload.meta_info = meta_info || config.meta_info
  }

  // add attributes
  if((typeof config.attributes === "object" && config.attributes != null)){
    payload.attributes = config.attributes
  }

  // add os, browser, device meta info.
  if(payload.meta_info){
    payload.meta_info.os = getOs()
    payload.meta_info.browser = getBrowser()
    payload.meta_info.device = getDevice()
  }

  let response;
  try{
    response = await updateContactInfo(payload)
    
    if(response.status !== 200){
      console.log("Error/showContactInfoUpdate", response)
    }
  }catch(err){
    console.log("Error/showContactInfoUpdate", err)
  }

  return response
}


// show in app notification
const ShowIAN = ()=>{
  
  // clean notification wrapper
  const popupNotificationWrapper = document.querySelector(".showInApp-popupNotification")
  const bannerNotificationWrapper = document.querySelector(".showIAN-bannerNotification")

  if(popupNotificationWrapper){
    document.body.removeChild(popupNotificationWrapper)
  }
  
  if(bannerNotificationWrapper){
    document.body.removeChild(bannerNotificationWrapper)
  }

  const node = document.createElement("div")
  node.classList.add("showInApp-popupNotification")

  if(document.body){
    document.body.appendChild(node)
  }

  Modal.setAppElement(`.showInApp-popupNotification`)
  ReactDOM.render(
    <InAppNotificationContextProvider>
      <App />
    </InAppNotificationContextProvider>,
    node
  );
}

ShowIAN();


// window.Player = function () {
//   ReactDOM.render(
//     <App />,
//     document.getElementById('root')
//   );
//   return this;
// };
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
