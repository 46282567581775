// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showIAN-bannerNotification{
  position: sticky;
  top: 0;
  z-index: 99999;
}

.showIAN-bannerNotification a{
  text-decoration: none !important;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,MAAM;EACN,cAAc;AAChB;;AAEA;EACE,gCAAgC;AAClC","sourcesContent":[".showIAN-bannerNotification{\n  position: sticky;\n  top: 0;\n  z-index: 99999;\n}\n\n.showIAN-bannerNotification a{\n  text-decoration: none !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
