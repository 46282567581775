import { sha512 as getT } from "js-sha512";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { DISPLAYED_NOTIFICATIONS, IAN_SESSION_ID, IAN_VISITOR_ID } from "../static";
import { getRandomShowVisitorId, STORAGE } from "..";
const SALT = "x9nFgM1ioxAOPmT3Fdyeh483lerc1J7k";

export const localStorageTest = () => {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const sessionStorageTest = () => {
  var test = "test";
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
};

export const getutctimestamp = () => {
  var dt = new Date(format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx"));
  return dt.getTime();
};

export const stringEncode = (payload) => {
  let encodedString = btoa(payload);
  encodedString = btoa(encodedString);
  return encodedString;
};

export const getSalt = () => SALT;

export const payloadHashKafka = (payload, timeval) =>
  getT(payload + getSalt() + timeval);

/* prepare payload hash */
export const payloadHash = (payload, timeval) => {
  let stringJSON = JSON.stringify(payload);
  let salt = getSalt(),
    epochTime = timeval,
    hashpayload = stringJSON.concat(salt);
  hashpayload = hashpayload.concat(epochTime);
  hashpayload = stringEncode(hashpayload);
  let hashToken = getT(hashpayload);
  return hashToken;
};

// get item from browser storage
export const getLocalStorageItem = (name, type) => {
  let storage = type === "session" ? window.sessionStorage : window.localStorage;
  return storage.getItem(name) ? JSON.parse(storage.getItem(name)) : null;
};

// set browser storage
export const setLocalStorageItem = (name, value, type) => {
  let storage = type === "session" ? window.sessionStorage : window.localStorage;
  storage.setItem(name, JSON.stringify(value));
};

// in-app notification
export const manageDisplayedNotification = (notificationData, localDisplayedNotifications) => {
  if(notificationData.session){
    const displayedNotifications = getLocalStorageItem(DISPLAYED_NOTIFICATIONS) || [];
    displayedNotifications.push(getNotificationUniqueIdByPath(notificationData));
    setLocalStorageItem(DISPLAYED_NOTIFICATIONS, displayedNotifications);
  }else{
    localDisplayedNotifications.current.push(getNotificationUniqueIdByPath(notificationData))
  }
};

// get unique notification id by current location
export const getNotificationUniqueId = (notification) => {
  const url = splitUrl(window.location.href);
  return `${url.path}/${notification.id}${notification.sid ? `/${notification.sid}` : ""}`;
};

// get unique notification id by its notification path
export const getNotificationUniqueIdByPath = (notification) => {
  return `${notification.notificationPath}/${notification.id}${notification.sid ? `/${notification.sid}` : ""}`;
};

// handle all notification (UI type, current UI type, callback)
export const handleNotification = (number, notificationType, callback) => {
  if (notificationType === number) {
    callback();
  }
};

// split url
export const splitUrl = (url) => {
  try {
    const href = new URL(url);
    return {
      domain: href.origin,
      path: href.pathname + href.search + href.hash,
    };
  } catch (e) {
    return null;
  }
};

// detect target dom
export const detectTargetDom = (selector, callback) => {

  // dom exists
  if (document.querySelector(selector)) {
    callback(true);
    return;
  }

  const observer = new MutationObserver((mutationsList, observer) => {
    if (document.querySelector(selector)) {
      observer.disconnect();
      callback(true);
    }
  });

  observer.observe(document.body, { childList: true, subtree: true });
};


// unique user tracking ids
export const getUserTrackingIds =  (key) => {

  let visitor = getLocalStorageItem(STORAGE.VISITOR)
  let session = getLocalStorageItem(STORAGE.SESSION, "session")

  if(!(visitor && visitor.id)){
    visitor = { id : getRandomShowVisitorId(key) }
    setLocalStorageItem(STORAGE.VISITOR, visitor)
  }

  if(!(session && session.id)){
    session = { id: getRandomShowVisitorId(key) }
    setLocalStorageItem(STORAGE.SESSION, session, "session")
  }

  return {
    visitor_id: visitor.id,
    session_id: session.id
  }
}

// generate logged-in user id
export const generateUId = (notification)=>{
  return `${notification.id}${notification.sid ? `.${notification.sid}` : ""}`
}

// add user id in notification
export const addUserId = (list, embedData)=>{
  return list.map(data =>({...data, sid: embedData.email ? btoa(embedData.email) : null }))
}


/* get rules group and condition */
export const getRulesAndCondition = (rule_query) => {
  if (typeof rule_query !== "object" || rule_query == null) {
    return {
      rules: null,
      condition: null,
    };
  }

  let condition = null;
  let rules = null;

  if (rule_query.hasOwnProperty("search__|")) {
    rules = rule_query["search__|"];
    condition = "search__|";
  } else if (rule_query.hasOwnProperty("search__&")) {
    rules = rule_query["search__&"];
    condition = "search__&";
  }

  return {
    rules: rules || null,
    condition: condition || null,
  };
};


const validateStringPattern = (id, currentValue, matchValue) => {
  const href = window.location.href
  switch (id) {
    case "is":
      return currentValue === matchValue;
    case "isnot":
      return currentValue !== matchValue;
    case "contains":
      return currentValue.includes(matchValue);
    case "doesnotcontain":
      return !currentValue.includes(matchValue);
    case "startswith":
      return currentValue.startsWith(matchValue);
    case "endswith":
      return currentValue.endsWith(matchValue);
    case "onlydomain":
      return (window.origin === href.slice(0, -1))
    case "anypath":
      return currentValue
    default:
      return false;
  }
};


export const validateGroup = ({rule, config, embedData, index}) => {
  const href = window.location.href
  const url = splitUrl(href)

  const origin = embedData.custom_domain || url.domain;
  const currentPath = url.path;

  // domain validation - is equal
  if (rule.search_key === "client.site_current_domain") {
    return origin === rule.search_item;
  }

  // path string pattern validation
  if (rule.search_key === "client.site_current_path") {
    return validateStringPattern(rule.search_meta_info.id, currentPath, rule.search_item)
  }


  /**
   * extract client trigger config
  */

  /* on load trigger */
  if (rule.search_key === "client.onload_trigger.dom") {
    config.target_onload_select_query = rule.search_item;
    config.rule_engine_type = 1;
    return true;
  }

  /* user interaction trigger */
  if (rule.search_key === "client.time_on_current_page") {
    config.interaction_time = Number(rule.search_item) * 1000; // in seconds
    return true;
  }

  /* on scroll trigger - DOM query */
  if (rule.search_key === "client.onscroll_trigger.dom") {
    config.scroll_target_select_query = rule.search_item;
    config.rule_engine_type = 3;
    return true;
  }

  /* on scroll trigger - root margin */
  if (rule.search_key === "client.onscroll_trigger.root_margin") {
    config.scroll_target_config.rootMargin = `${rule.search_item}px`;
    return true;
  }

  /* on scroll trigger - threshold */
  if (rule.search_key === "client.onscroll_trigger.threshold") {
    config.scroll_target_config.threshold = Number(rule.search_item) / 100; 
    return true;
  }

  return false;
};

// check is current date greter than seen date
export function dateComparison(currentDate, seenDate, operator) {
  const _currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
  const _seenDate = new Date(seenDate.getFullYear(), seenDate.getMonth(), seenDate.getDate());

  if(operator === 'less_than'){
    return _currentDate < _seenDate;
  }

  if(operator === 'greater_than'){
    return _currentDate > _seenDate;
  }

  if(operator === '>='){
    return _currentDate >= _seenDate;
  }

  if(operator === '>'){
    return _currentDate > _seenDate;
  }

  if(operator === '<='){
    return _currentDate <= _seenDate;
  }

  if(operator === '<'){
    return _currentDate < _seenDate;
  }

}

// add date
export const addDate = (date, days)=>{
  let newDate = new Date(date)
  return new Date(newDate.setDate(newDate.getDate() + days))
}

// normalize date
export const normalizeDate = (datObj)=>{
  return new Date(
    datObj.getFullYear(),
    datObj.getMonth(),
    datObj.getDate(),
    datObj.getHours(),
    datObj.getMinutes()
  );
}

// get OS, device, browser
export const getOs = () => {
  let platform = window.navigator.platform,
    userAgent = window.navigator.userAgent,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "macos";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "ios";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "windows";
  } else if (/Android/.test(userAgent)) {
    os = "android";
  } else if (!os && /Linux/.test(platform)) {
    os = "linux";
  }
  return os;
};

export const getBrowser = () => {
  let browser = null;
  // Opera 8.0+
  if (
    (!!window.opr && !!opr.addons) ||
    !!window.opera ||
    navigator.userAgent.indexOf(" OPR/") >= 0
  ) {
    browser = "opera";
  } else if (typeof InstallTrigger !== "undefined") {
    // Firefox 1.0+
    browser = "firefox";
  } else if (
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(
      !window["safari"] ||
        (typeof safari !== "undefined" && safari.pushNotification)
    )
  ) {
    // Safari 3.0+ "[object HTMLElementConstructor]"
    browser = "safari";
  } else if (
    /Safari/i.test(navigator.userAgent) &&
    /Apple Computer/.test(navigator.vendor) &&
    !/Mobi|Android/i.test(navigator.userAgent)
  ) {
    //Desktop Safari
    browser = "safari";
  } else if (
    !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream
  ) {
    browser = "safari";
  } else if (false || !!document.documentMode) {
    // Internet Explorer 6-11
    browser = "ie";
  } else if (!(false || !!document.documentMode) && !!window.StyleMedia) {
    // Edge 20+
    browser = "edge";
  } else if (
    navigator.userAgent.toLowerCase().indexOf("chrome") !== -1 &&
    navigator.userAgent.toLowerCase().indexOf("edg") !== -1
  ) {
    browser = "edge";
  } else if (navigator.userAgent.indexOf("SamsungBrowser") > -1) {
    browser = "samsung";
  } else if (navigator.userAgent.indexOf(" Ucbrowser ") > -1) {
    browser = "UcBrowser";
  } else if (
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime)
  ) {
    // Chrome 1 - 79
    browser = "chrome";
  } else if (
    !!window.chrome &&
    window.navigator.userAgent.toLowerCase().indexOf("chrome") > -1
  ) {
    // Chrome 1 - 79
    browser = "chrome";
  } else if (
    !!window.chrome &&
    (!!window.chrome.webstore || !!window.chrome.runtime) &&
    navigator.userAgent.indexOf("Edg") != -1
  ) {
    // Edge (based on chromium) detection
    browser = "edgechromium";
  } else {
    browser = "Unknown";
  }
  return browser;
};

export const getDevice = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "tablet";
  }
  if (
    /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "mobile";
  }
  return "desktop";
};
