import React, { useEffect, useRef } from "react";
import { useInAppNotificationContext } from "../../context/InAppNotificationContext";
import "./Post.css";
import { protectedPostStyles } from "../../config/staticData";

const Post = (props) => {
  const { html_content } = props.notification
  const { trackNotificationUserEvent } = useInAppNotificationContext();

  const containerRef = useRef(null);

  // render and click tracking - useEffect
  useEffect(() => {
    const container = containerRef.current

    if (!container) return;

    if (container.shadowRoot) {
      container.shadowRoot.innerHTML = ""
    } else {
      container.attachShadow({ mode: "open" });
    }

    const shadowRoot = container.shadowRoot

    // css and content
    const style = document.createElement("style")
    style.textContent = protectedPostStyles

    const wrapper = document.createElement("div")
    wrapper.innerHTML = html_content

    shadowRoot.appendChild(style)
    shadowRoot.appendChild(wrapper)

    // handle anchor tag click
    const anchorTagClick = (event) => {
      const clickedUrl = event.currentTarget.href
      if (clickedUrl) {
        trackNotificationUserEvent({
          signal_name: "notification_click_signal",
          notification_data: {
            name: props.notification.name,
            id: props.notification.id,
            url: clickedUrl
          }
        })
      }
    };

    // add listeners to anchor tag
    const anchorTags = wrapper.querySelectorAll("a")

    if(anchorTags){
      anchorTags.forEach((anchor) => {
        anchor.addEventListener("click", anchorTagClick)
      })
    }

    // cleanup
    return () => {
      const anchorTags = wrapper.querySelectorAll("a")
      if(anchorTags){
        anchorTags.forEach((anchor) => {
          anchor.removeEventListener("click", anchorTagClick)
        })
      }
    }
  }, [html_content])

  return (
    <section className="showIAN-post-popupContainer showIAN-blockContainer-reset" ref={containerRef}>
    </section>
  )
}

export default Post