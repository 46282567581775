import React from "react";
import SWCrossIcon from "../../icon/SWCrossIcon";
import ReactModal from 'react-modal';

import "./Modal.css";
import SWCross from "../../icon/SWCross";

const Modal = (props) => {

  return (
    <ReactModal {...props}>
      <section className="showIAN-modalContainer showIAN-blockContainer-reset"
        style={{
          background: props?.notification?.content_bg || ""
        }}
      >
        {props.notification.notification.category !== 4 ? (
          <>
            <div className="showIAN-modalHeader showIAN-blockContainer-reset">
              <span
                onClick={props.closeModal}
                style={{ width: "18px", height: "16.5px" }}>
                <SWCrossIcon />
              </span>
            </div>
            <hr className="horizontalSplitter" />
          </>
        ) : (
          <div className="showIAN-postModalHeader">
            <button
              className="showIAN-transparent-btn"
              onClick={props.closeModal}
              style={{
                width: "fit-content",
                height: "fit-content",
                cursor: "pointer"
              }}>
              <SWCross width={8.5} height={8.5} />
            </button>
          </div>
        )}
        {props.children}
      </section>
    </ReactModal>
  );
}


export default Modal