// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter+Tight:wght@300&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.show-IAN-flexCenter{
  display: flex;
  justify-content: center;
}

.showIAN-transparent-btn{
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 16px;
  background: transparent;
  border: none;
  outline: 0;
}

.showIAN-transparent-btn:hover, .showIAN-transparent-btn:active{
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAEA;EACE,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,eAAe;EACf,iBAAiB;EACjB,uBAAuB;EACvB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:wght@300&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');\n\nbody {\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.show-IAN-flexCenter{\n  display: flex;\n  justify-content: center;\n}\n\n.showIAN-transparent-btn{\n  margin: 0;\n  padding: 0;\n  font-size: 16px;\n  line-height: 16px;\n  background: transparent;\n  border: none;\n  outline: 0;\n}\n\n.showIAN-transparent-btn:hover, .showIAN-transparent-btn:active{\n  background: transparent;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
