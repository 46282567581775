import React, { createContext, useContext, useState } from "react";
import { notificationUserEvents } from "../Api";

export const InAppNotificationContext = createContext({});

const InAppNotificationContextProvider = (props) => {

  // states
  const [metaInfo, setMetaInfo] = useState(null);
  const [userTrackingIds, setUserTrackingIds] = useState({
    visitor_id: "",
    session_id: ""
  });
  const [embedData, setEmbedData] = useState(null)

  // user event handler
  const trackNotificationUserEvent = (event) => {

    let payload = {
      visitor_id: userTrackingIds.visitor_id,
      session_id: userTrackingIds.session_id,
      owner_account_id: embedData.owner_account_id,
      email: embedData.email,
      meta_info: metaInfo,
      signal_name: event.signal_name,
      notification_data: {
        ...event.notification_data,
        site_url: window.location.href
      }
    }

    // user events api call
    notificationUserEvents(payload)
  };

  return (
    <InAppNotificationContext.Provider
      value={{
        trackNotificationUserEvent,
        globalMetaInfo: [metaInfo, setMetaInfo],
        globalUserTrackingIds: [userTrackingIds, setUserTrackingIds],
        globalEmbedData: [embedData, setEmbedData]
      }}
    >
      {props.children}
    </InAppNotificationContext.Provider>
  );
};

export const useInAppNotificationContext = () => {
  return useContext(InAppNotificationContext);
};

export default InAppNotificationContextProvider;
